import BotaoItemCartao from '../BotaoItemCartao';
import SvgIcon from '@mui/material/SvgIcon';

export default function Deezer(props) {

    const DeezerIcon = (props) => {
        return (
            <SvgIcon {...props}>
                <path d="M17.68,5.47L22,5.47 22,8 17.68,8z M17.68,8.98L22,8.98 22,11.51 17.68,11.51z M17.68,12.49L22,12.49 22,15.02 17.68,15.02z M2,16L6.32,16 6.32,18.53 2,18.53z M7.22,16L11.54,16 11.54,18.53 7.22,18.53z M12.45,16L16.77,16 16.77,18.53 12.45,18.53z M17.68,16L22,16 22,18.53 17.68,18.53z M12.45,12.49L16.77,12.49 16.77,15.02 12.45,15.02z M7.22,12.49L11.54,12.49 11.54,15.02 7.22,15.02z M7.22,8.98L11.54,8.98 11.54,11.51 7.22,11.51z" />
            </SvgIcon>
        );
    }

    const handleClick = (url_deezer) => {
        window.open(url_deezer);       
    }

    return (
        <BotaoItemCartao nomeItem="Deezer"  subNome={ props.subNome || '' } onClick={ () => handleClick(props.dadoPrincipal) } icon={<DeezerIcon sx={{ fontSize: 30 }} />} />
    );
}

