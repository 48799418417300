import SvgIcon from '@mui/material/SvgIcon';
import BotaoItemCartao from '../BotaoItemCartao';

export default function Discord(props) {

    const DiscordIcon = (props) => {
        return (
            <SvgIcon {...props}>
                <path d="M19,5.67l0,0a16.56,16.56,0,0,0-4.07-1.26.09.09,0,0,0-.07,0c-.18.34-.35.69-.5,1a15.22,15.22,0,0,0-4.57,0c-.16-.35-.33-.7-.52-1a.07.07,0,0,0-.06,0A16.64,16.64,0,0,0,5.06,5.64l0,0a16.83,16.83,0,0,0-3,11.38.05.05,0,0,0,0,0,16.78,16.78,0,0,0,5,2.53.08.08,0,0,0,.07,0,10.87,10.87,0,0,0,1-1.66.05.05,0,0,0,0-.08h0A10.11,10.11,0,0,1,6.6,17.1a.07.07,0,0,1,0-.09,0,0,0,0,0,0,0,3.65,3.65,0,0,0,.31-.24.09.09,0,0,1,.07,0,11.78,11.78,0,0,0,10.05,0,.06.06,0,0,1,.06,0,3.81,3.81,0,0,0,.32.24s0,.07,0,.09l0,0a10.11,10.11,0,0,1-1.56.74.08.08,0,0,0,0,.09h0a13.5,13.5,0,0,0,1,1.66.08.08,0,0,0,.07,0,16.6,16.6,0,0,0,5-2.53.05.05,0,0,0,0,0A16.72,16.72,0,0,0,19,5.67ZM8.68,14.77a1.92,1.92,0,0,1-1.79-2,1.91,1.91,0,0,1,1.79-2,1.91,1.91,0,0,1,1.8,2A1.91,1.91,0,0,1,8.68,14.77Zm6.65,0a1.92,1.92,0,0,1-1.8-2,1.92,1.92,0,0,1,1.8-2,1.91,1.91,0,0,1,1.8,2A1.9,1.9,0,0,1,15.33,14.77Z"/>
            </SvgIcon>
        );
    }

    const handleClick = (discord) => {
        window.open("https://discord.gg/" + discord);
    }

    return (
        <BotaoItemCartao nomeItem="Discord" subNome={ props.subNome || '/' + props.dadoPrincipal } onClick={ () => handleClick(props.dadoPrincipal) } icon={<DiscordIcon sx={{ fontSize: 30 }} />} />
    );
}
