import axios from "axios";
import React, { useEffect, useState } from "react";
import { Box, Container } from '@mui/material'
import Typography from '@mui/material/Typography';
import Avatar from '@mui/material/Avatar';

import WhatsApp from "./tiposBotoes/WhatsApp";
import Instagram from "./tiposBotoes/Instagram";
import Facebook from "./tiposBotoes/Facebook";
import Pix from "./tiposBotoes/Pix";
import Site from "./tiposBotoes/Site";
import Endereco from "./tiposBotoes/Endereco";
import Email from "./tiposBotoes/Email";
import Linkedin from "./tiposBotoes/Linkedin";
import Lattes from "./tiposBotoes/Lattes";
import CanalYoutube from "./tiposBotoes/CanalYoutube";
import Spotfy from "./tiposBotoes/Spotfy";
import TikTok from "./tiposBotoes/TikTok";
import NuBank from "./tiposBotoes/NuBank";
import Deezer from "./tiposBotoes/Deezer";
import Twitter from "./tiposBotoes/Twitter";
import Telegram from "./tiposBotoes/Telegram";
import Discord from "./tiposBotoes/Discord";
import PicPay from "./tiposBotoes/PicPay";
import SalvarContato from "./tiposBotoes/SalvarContato";


const getHost = () => {
    return window.location.protocol + "//" + window.location.hostname;
}

const consultarDadosUsuario = async (username) => {
    const response = await axios.get(`${getHost()}/api/username/${username}`);
    return response.data;
}

export default function DadosUsuario(props) {
    const [state, setState] = useState({});
    const avatar = '../avatar/' + state.avatar;

    useEffect(() => {
        
        async function fetchDadosUsuario() {
            let { username } = props;
            let dados = await consultarDadosUsuario(username);
            setState(dados);
        }

        fetchDadosUsuario();
    }, [props]);

    
    
    const styles = {
        buttonContainer : {
            marginTop: '30px'
        },

        boxContainer : {
            height: '100%',
            'padding-top': 80,
            'text-align': 'center'
        },

        avatar : {
            width: 130, 
            height: 130, 
            margin: 'auto',
            'margin-bottom' : '25px'
        },

        btSalvarContato : {
            position: 'absolute',
            top: '10px',
            right: '10px'
        }
    };
    
    // document.getElementById('bg-image').style.setProperty('background-color', state.backgroundColor || "#ebeef1");
    document.body.style.setProperty('background-color', state.backgroundColor || "#ebeef1");
    document.getElementById('bg-image').style.setProperty('background-image', "url('../avatar/" + avatar + "')");

    const criarBotao = (tipoBotao, dados) => {
        let dadoPrincipal, subNome;

        if(typeof dados === 'object' && dados !== null){
            dadoPrincipal = dados.dadoPrincipal;
            subNome = dados.subNome;
        } else if(dados !== null) {
            dadoPrincipal = dados;
        } else {
            return '';
        }

        const tiposBotoes = {
            "whatsapp" : <WhatsApp />,
            "telegram" : <Telegram />,
            "instagram" : <Instagram />,
            "facebook" : <Facebook />,
            "twitter" : <Twitter />,
            "discord" : <Discord />,
            "tiktok" : <TikTok />,
            "canalYoutube" : <CanalYoutube />,
            "spotfy" : <Spotfy />,
            "deezer" : <Deezer />,
            "linkedin" : <Linkedin />,
            "lattes" : <Lattes />,
            "pix" : <Pix />,
            "nubank" : <NuBank />,
            "picpay" : <PicPay />,
            "site" : <Site />,
            "endereco" : <Endereco />,
            "email" : <Email />,
        }
        
        try{
            return React.cloneElement(tiposBotoes[tipoBotao], { dadoPrincipal: dadoPrincipal,  subNome: subNome});
        } catch (e) {
            return '';
        }
    }
 
    const botoesDoUsuario = Object.keys(state).map(key => criarBotao(key, state[key]));

    return (
        <Container maxWidth="false" sx={ styles.boxContainer }>
            <Avatar
                alt={state.nome}
                src={avatar !== undefined ? avatar : ''}
                sx={ styles.avatar }/>

            <Typography variant="h5" gutterBottom component="div" marginBottom="0px">
                    {state.nome}
            </Typography>

            <Typography variant="body1" gutterBottom component="div" marginBottom="0px" sx={{ color: 'text.secondary' }}>
                    {state.biografiaPrimaria}
            </Typography>
            <Typography variant="body1" gutterBottom component="div" sx={{ color: 'text.secondary', fontStyle: 'italic' }}>
                    {state.biografiaSecundaria}
            </Typography>

            {state.whatsapp && state.mostrarSalvarContato ?
                <Box sx={ styles.btSalvarContato } >
                    <SalvarContato href={`${getHost()}/api/vcard/${props.username}`}/>
                </Box>
                : ''
            }
            <Box sx={ styles.buttonContainer } maxWidth="600px" margin={'auto'}>
                { botoesDoUsuario }
            </Box>
            <Typography gutterBottom color={'text.secondary'} sx={{fontSize:15}}  marginTop="40px">
                2022 © by carloshenrique.ch
            </Typography>
        </Container>
    );
}