import InstagramIcon from '@mui/icons-material/Instagram';
import BotaoItemCartao from '../BotaoItemCartao';

export default function Instagram(props) {
    const handleClick = (instagram) => {
        window.open("https://instagram.com/" + instagram);
    }

    return (
        <BotaoItemCartao nomeItem="Instagram" subNome={ props.subNome || '@' + props.dadoPrincipal } onClick={ () => handleClick(props.dadoPrincipal) } icon={<InstagramIcon sx={{ fontSize: 30 }} />} />
    );
}