import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import ArrowRightIcon from '@mui/icons-material/ArrowRight';
import { Stack } from '@mui/material';

export default function BotaoItemCartao(props) {
    const styles = {
        gridContainer : {
            height: '65px',
            padding: '5px',
            paddingLeft: '18px',
            color: 'primary.main',
            paddingRight: '18px',
            borderRadius: 3,
            marginBottom: '20px',
            backgroundColor: '#ffffffcc',
            '&:active': {
                backgroundColor: 'primary.main',
                color:'#ffffff'
            },
            alignItems: 'center',
            direction: 'row',
            spacing: 0,
            cursor: 'pointer'
        }
    };

    return (
        <Grid container sx={ styles.gridContainer } onClick={ props.onClick } >
            <Grid item xs="auto" ms="auto" sm="auto" sx={{textAlign: 'left'}}>
                <Stack>{ props.icon }</Stack>
            </Grid>
            <Grid item xs={7} ms={9} sm={7} sx={{textAlign: 'left', paddingLeft: '15px'}}>
                <Typography variant="subtitle2"  noWrap>{ props.nomeItem }</Typography>
                <Typography variant="caption" color={'text.secondary'}  noWrap>{ props.subNome }</Typography>
            </Grid>
            <Grid item xs ms sm >
                { 
                    props.nomeAcao ? 
                    <Typography variant="body2"  noWrap> 
                        <Stack alignItems={'end'}>
                            { props.nomeAcao }
                        </Stack>
                    </Typography> : 
                    <Stack alignItems={'end'}>
                        <ArrowRightIcon />
                    </Stack> 
                }
            </Grid>
        </Grid>
    );
}