import AlternateEmailIcon from '@mui/icons-material/AlternateEmail';
import BotaoItemCartao from '../BotaoItemCartao';
import SendIcon from '@mui/icons-material/Send';

export default function Email(props) {
    const handleClick = (email) => {
        window.open("mailto:" + email);
    }

    return (
        <BotaoItemCartao nomeItem="E-mail" subNome={ props.subNome || props.dadoPrincipal } nomeAcao={ <SendIcon sx={{ fontSize: 23 }}/> } onClick={ () => handleClick(props.dadoPrincipal) } icon={<AlternateEmailIcon sx={{ fontSize: 30 }} />} />
    );
}