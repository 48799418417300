import LocationOnIcon from '@mui/icons-material/LocationOn';
import BotaoItemCartao from '../BotaoItemCartao';
import MapIcon from '@mui/icons-material/Map';

export default function Endereco(props) {
    const handleClick = (endereco) => {
        // window.open("https://www.google.com/maps?q=" + endereco.replaceAll(' ',  '+'));
        window.open(endereco);
    }

    return (
        <BotaoItemCartao nomeItem="Endereço" subNome={ props.subNome || props.dadoPrincipal } nomeAcao={ <MapIcon sx={{ fontSize: 23 }}/> } onClick={ () => handleClick(props.dadoPrincipal) } icon={<LocationOnIcon sx={{ fontSize: 30 }} />} />
    );
}