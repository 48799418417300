import SvgIcon from '@mui/material/SvgIcon';
import BotaoItemCartao from '../BotaoItemCartao';

export default function TikTok(props) {

    const TikTokIcon = (props) => {
        return (
            <SvgIcon {...props}>
                <path d="M20.75,10.2A8.18,8.18,0,0,1,16,8.67v7A6.35,6.35,0,1,1,9.6,9.3a6.25,6.25,0,0,1,.87.06v3.51a2.94,2.94,0,1,0,2,2.78V2H16a4.27,4.27,0,0,0,.08.87h0a4.84,4.84,0,0,0,4.72,3.92Z"/>
            </SvgIcon>
        );
    }

    const handleClick = (tiktok) => {
        window.open("https://www.tiktok.com/@" + tiktok);
    }

    return (
        <BotaoItemCartao nomeItem="TikTok" subNome={ props.subNome || '@' + props.dadoPrincipal } onClick={ () => handleClick(props.dadoPrincipal) } icon={<TikTokIcon sx={{ fontSize: 30 }} />} />
    );
}
