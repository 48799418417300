module.exports = () => {
    const utils = {};

    utils.copyToClipBoard = (text) => {
        if (!navigator.clipboard) {
            fallbackCopyTextToClipboard(text);
            return;
        }
        navigator.clipboard.writeText(text).then(function() {
            console.log('Async: Copying to clipboard was successful!');
        }, function(err) {
            console.error('Async: Could not copy text: ', err);
        });
    }

    utils.formatarTelefone = (num_telefone) => {
      let telefoneFormatado = '+';

      telefoneFormatado += num_telefone.substring(0,2);
      telefoneFormatado +=  ' ' + num_telefone.substring(2, 4);
      telefoneFormatado +=  ' ' + num_telefone.substring(4, 5) + '.';
      telefoneFormatado += num_telefone.substring(5, 9) + '-';
      telefoneFormatado += num_telefone.substring(9, 14);

      return telefoneFormatado;
    }

    const fallbackCopyTextToClipboard = (text) => {
        var textArea = document.createElement("textarea");
        textArea.value = text;
        
        // Avoid scrolling to bottom
        textArea.style.top = "0";
        textArea.style.left = "0";
        textArea.style.position = "fixed";
      
        document.body.appendChild(textArea);
        textArea.focus();
        textArea.select();
      
        try {
          var successful = document.execCommand('copy');
          var msg = successful ? 'successful' : 'unsuccessful';
          console.log('Fallback: Copying text command was ' + msg);
        } catch (err) {
          console.error('Fallback: Oops, unable to copy', err);
        }
      
        document.body.removeChild(textArea);
      }

    return utils;
}