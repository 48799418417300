import WhatsAppIcon from '@mui/icons-material/WhatsApp';
import BotaoItemCartao from '../BotaoItemCartao';
const utils = require('../../../utils/utils')();

export default function WhatsApp(props) {
    const handleClick = (telefone) => {
        window.open("https://api.whatsapp.com/send?phone=" + telefone);
    }

    return (
        <BotaoItemCartao nomeItem="WhatsApp" subNome={ props.subNome || utils.formatarTelefone(props.dadoPrincipal) } onClick={ () => handleClick(props.dadoPrincipal) } icon={<WhatsAppIcon sx={{ fontSize: 30 }} />} />
    );
}