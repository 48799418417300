import TelegramIcon from '@mui/icons-material/Telegram';
import BotaoItemCartao from '../BotaoItemCartao';

export default function Telegram(props) {
    const handleClick = (telegram) => {
        window.open("https://t.me/" + telegram);
    }

    return (
        <BotaoItemCartao nomeItem="Telegram" subNome={ props.subNome || '/' + props.dadoPrincipal } onClick={ () => handleClick(props.dadoPrincipal) } icon={<TelegramIcon sx={{ fontSize: 30 }} />} />
    );
}