import React from 'react';
import { BrowserRouter, Switch, Route, useParams } from 'react-router-dom';

import DadosUsuario from './components/estrutura/DadosUsuario';
// import AppBarMasterPlaca from './components/estrutura/AppBarMasterPlaca'
// import FormEdicaoPlaca from './components/form/FormEdicaoPlaca';


export default function App(props) {
    return (
        <BrowserRouter>
            <Switch>
                <Route path='/:username' children={<PerfilUsuario />} />
            </Switch>
        </BrowserRouter>
    );
}

function PerfilUsuario(props) {
    let { username } = useParams();
    if(!username) username = props.username;

    return (
        <DadosUsuario username={username} />
    );
}
