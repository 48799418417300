import FacebookIcon from '@mui/icons-material/Facebook';
import BotaoItemCartao from '../BotaoItemCartao';

export default function Facebook(props) {
    const handleClick = (facebook) => {
        window.open("https://www.facebook.com/" + facebook);
    }

    return (
        <BotaoItemCartao nomeItem="Facebook" subNome={ props.subNome || '/' + props.dadoPrincipal } onClick={ () => handleClick(props.dadoPrincipal) } icon={<FacebookIcon sx={{ fontSize: 30 }} />} />
    );
}