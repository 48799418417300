import TwitterIcon from '@mui/icons-material/Twitter';
import BotaoItemCartao from '../BotaoItemCartao';

export default function Twitter(props) {
    const handleClick = (twitter) => {
        window.open("https://twitter.com/" + twitter);
    }

    return (
        <BotaoItemCartao nomeItem="Twitter" subNome={ props.subNome || '@' + props.dadoPrincipal } onClick={ () => handleClick(props.dadoPrincipal) } icon={<TwitterIcon sx={{ fontSize: 30 }} />} />
    );
}