import HistoryEduIcon from '@mui/icons-material/HistoryEdu';
import BotaoItemCartao from '../BotaoItemCartao';

export default function Lattes(props) {
    const handleClick = (codigoLattes) => {
        window.open("http://lattes.cnpq.br/" + codigoLattes);
    }

    return (
        <BotaoItemCartao nomeItem="Lattes" subNome={ props.subNome || '/' + props.dadoPrincipal } onClick={ () => handleClick(props.dadoPrincipal) } icon={<HistoryEduIcon sx={{ fontSize: 30 }} />} />
    );
}