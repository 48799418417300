import QrCode2Icon from '@mui/icons-material/QrCode2';
import BotaoItemCartao from '../BotaoItemCartao';
import SvgIcon from '@mui/material/SvgIcon';

export default function PicPay(props) {

    const PicPayIcon = (props) => {
        return (
            <SvgIcon {...props}>
                <path d="M9.87,10a2.86,2.86,0,0,0-1-.17h-2a.23.23,0,0,1-.18,0,.18.18,0,0,1,0,.11v3.51c0,.13,0,.16.16.16H8.84a2.83,2.83,0,0,0,1.11-.2,1.59,1.59,0,0,0,1-1.16,2.25,2.25,0,0,0-.08-1.31A1.52,1.52,0,0,0,9.87,10Z"/>
                <path d="M18.93,6.2c0-.09,0-.11-.11-.11h-3c-.09,0-.12,0-.12.11,0,1,0,2,0,3,0,.09,0,.12.12.12h3c.11,0,.12,0,.12-.13v-3ZM18,8.53H16.6c-.08,0-.11,0-.11-.11,0-.47,0-.94,0-1.42,0-.07,0-.1.1-.1H18c.09,0,.1,0,.1.1V8.41C18.12,8.5,18.1,8.53,18,8.53Z"/>
                <path d="M19,2H5A3,3,0,0,0,2,5V19a3,3,0,0,0,3,3H19a3,3,0,0,0,3-3V5A3,3,0,0,0,19,2ZM13.35,12.23a4.21,4.21,0,0,1-.49,1.54,3.35,3.35,0,0,1-1.72,1.49,5.59,5.59,0,0,1-2.1.39H6.81c-.1,0-.13,0-.13.13,0,.94,0,1.88,0,2.82,0,.1,0,.13-.13.13H4.39c-.11,0-.13,0-.13-.13V9.89c0-.1,0-.14.13-.14H6.68q0-1,0-1.92c0-.1,0-.13.13-.13H9.18a5.53,5.53,0,0,1,2.12.42,3.32,3.32,0,0,1,1.94,2.22A5.11,5.11,0,0,1,13.35,12.23Zm6.28-2.08H15c-.09,0-.12,0-.12-.11V5.39c0-.09,0-.11.11-.11h4.65c.1,0,.11,0,.11.12V10C19.74,10.12,19.72,10.15,19.63,10.15Z"/>
            </SvgIcon>
        );
    }

    const handleClick = (url_pagar) => {
        window.open(url_pagar);       
    }

    return (
        <BotaoItemCartao nomeItem="PicPay" subNome={ props.subNome || '' } nomeAcao={<QrCode2Icon sx={{ fontSize: 23 }}/>} onClick={ () => handleClick(props.dadoPrincipal) } icon={<PicPayIcon sx={{ fontSize: 30 }} />} />
    );
}

