import LinkedInIcon from '@mui/icons-material/LinkedIn';
import BotaoItemCartao from '../BotaoItemCartao';

export default function Linkedin(props) {
    const handleClick = (linkedin) => {
        window.open("https://www.linkedin.com/in/" + linkedin);
    }

    return (
        <BotaoItemCartao nomeItem="Linkedin" subNome={ props.subNome || '/' + props.dadoPrincipal } onClick={ () => handleClick(props.dadoPrincipal) } icon={<LinkedInIcon sx={{ fontSize: 30 }} />} />
    );
}