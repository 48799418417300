import * as React from 'react';
import SaveAltIcon from '@mui/icons-material/SaveAlt';
import MuiAlert from '@mui/material/Alert';
import Snackbar from '@mui/material/Snackbar';
import { Button } from '@mui/material';
import CheckIcon from '@mui/icons-material/Check';

export default function SalvarContato(props) {
    const [open, setOpen] = React.useState(false);

    const handleClick = () => {
        // utils.copyToClipBoard(pix);
        setOpen(true);
    }

    const handleClose = (event, reason) => {
        if (reason === 'clickaway') {
            return;
        }

        setOpen(false);
    };

    return (
        <div>
            <Button variant="contained" 
                    startIcon={open ? <CheckIcon /> : <SaveAltIcon />} 
                    href={open ? props.href : ' '} 
                    onClick={ () => handleClick() } 
                    color={ open ? 'success' : 'primary'}>{open ? 'Salvar' : 'Salvar'}</Button>

            <Snackbar open={open} autoHideDuration={6000} onClose={handleClose}>
                <MuiAlert onClose={handleClose} 
                    severity="success" sx={{ width: '100%' }} 
                    elevation={6} 
                    variant="filled">Aguarde o download e abra o arquivo!</MuiAlert>
            </Snackbar>
        </div>
    );
}