import SvgIcon from '@mui/material/SvgIcon';
import BotaoItemCartao from '../BotaoItemCartao';

export default function Spotfy(props) {

    const SpotfyIcon = (props) => {
        return (
            <SvgIcon {...props}>
                <path d="M11.42,2h1.17a.8.8,0,0,0,.15,0,9.3,9.3,0,0,1,3.48.91,9.87,9.87,0,0,1,5.62,7.32c.08.38.11.77.16,1.15v1.17a1.22,1.22,0,0,0,0,.17,9.17,9.17,0,0,1-.92,3.49A9.83,9.83,0,0,1,12.42,22a9.13,9.13,0,0,1-5.11-1.16A9.79,9.79,0,0,1,2,12.42,9.37,9.37,0,0,1,3.18,7.29a9.87,9.87,0,0,1,7.1-5.13C10.65,2.08,11,2.05,11.42,2ZM10.26,7.27H9.48a15.14,15.14,0,0,0-3.84.59,1.26,1.26,0,0,0-.46.24A1,1,0,0,0,5,9.3a.94.94,0,0,0,1.15.42,14.39,14.39,0,0,1,3.05-.5,18.47,18.47,0,0,1,3.86.17,13.88,13.88,0,0,1,3.63,1c.38.17.74.37,1.12.55A.91.91,0,0,0,19,10.56a1,1,0,0,0-.45-1.42A13.52,13.52,0,0,0,14.6,7.7,19.2,19.2,0,0,0,10.26,7.27Zm-.18,3.43H9.59a13.93,13.93,0,0,0-3.4.53.82.82,0,0,0-.6,1,.79.79,0,0,0,1,.54,13.11,13.11,0,0,1,6.1-.24A12,12,0,0,1,16.55,14a.74.74,0,0,0,1-.24.83.83,0,0,0-.2-1.11,10,10,0,0,0-.95-.54A14.28,14.28,0,0,0,10.08,10.7Zm.15,3.23L8.69,14,8,14.12c-.54.09-1.09.19-1.63.32a.65.65,0,0,0,.33,1.25c.71-.12,1.42-.26,2.14-.35a11.48,11.48,0,0,1,3.46.11,9.17,9.17,0,0,1,2.95,1.07,3,3,0,0,0,.4.2.61.61,0,0,0,.72-.32.68.68,0,0,0-.21-.86,9.42,9.42,0,0,0-.88-.49A11.73,11.73,0,0,0,10.23,13.93Z"/>
            </SvgIcon>
        );
    }

    const handleClick = (spotfy) => {
        window.open("https://www.youtube.com/c/" + spotfy);
    }

    return (
        <BotaoItemCartao nomeItem="Playlist Spotfy" subNome={ props.subNome || '/' + props.dadoPrincipal } onClick={ () => handleClick(props.dadoPrincipal) } icon={<SpotfyIcon sx={{ fontSize: 30 }} />} />
    );
}
