import * as React from 'react';
import PixIcon from '@mui/icons-material/Pix';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import ContentCopyIcon from '@mui/icons-material/ContentCopy';
import BotaoItemCartao from '../BotaoItemCartao';
import MuiAlert from '@mui/material/Alert';
import Snackbar from '@mui/material/Snackbar';
const utils = require('../../../utils/utils')();

export default function Pix(props) {
    const [open, setOpen] = React.useState(false);

    const handleClick = (pix) => {
        utils.copyToClipBoard(pix);
        setOpen(true);
    }

    const handleClose = (event, reason) => {
        if (reason === 'clickaway') {
            return;
        }

        setOpen(false);
    };

    return (
        <div>
            <BotaoItemCartao nomeItem="Chave PIX" 
                subNome={ props.subNome || props.dadoPrincipal } 
                nomeAcao={
                    open ? <CheckCircleIcon color='success'  sx={{ fontSize: 23 }}/> : 
                            <ContentCopyIcon  sx={{ fontSize: 23 }}/>
                } 
                onClick={ () => handleClick(props.dadoPrincipal) } 
                icon={<PixIcon sx={{ fontSize: 30 }} />} />

            <Snackbar open={open} autoHideDuration={6000} onClose={handleClose}>
                <MuiAlert onClose={handleClose} severity="success" sx={{ width: '100%' }} elevation={6} variant="filled">PIX copiado com sucesso!</MuiAlert>
            </Snackbar>
        </div>
    );
}