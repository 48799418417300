import QrCode2Icon from '@mui/icons-material/QrCode2';
import BotaoItemCartao from '../BotaoItemCartao';
import SvgIcon from '@mui/material/SvgIcon';

export default function NuBank(props) {

    const NuBankIcon = (props) => {
        return (
            <SvgIcon {...props}>
                <path d="M15.91,17.52h-.12A3.17,3.17,0,0,1,14.08,17a3.27,3.27,0,0,1-1-1.08,4.48,4.48,0,0,1-.53-1.43,5.43,5.43,0,0,1-.1-.81c0-.41,0-.82,0-1.24s0-.82,0-1.23V6.74h2.63v4.19c0,.38,0,.75,0,1.13s0,.6,0,.9a4.35,4.35,0,0,0,.36,1.67,3,3,0,0,0,1.32,1.45,3.4,3.4,0,0,0,1,.33,4.18,4.18,0,0,0,.61.05h0l-.12.12a3.38,3.38,0,0,1-1.63.85,3.18,3.18,0,0,1-.62.07h-.11Z"/>
                <path d="M2,11.33s0,0,0-.05c0-.22,0-.44,0-.65A3.38,3.38,0,0,1,2.89,8.7a3.56,3.56,0,0,1,1.63-1,3.12,3.12,0,0,1,1.05-.13h0l0,0a3.32,3.32,0,0,0-.8,1.57,5,5,0,0,0-.11.85c0,.3,0,.6,0,.9s0,.69,0,1v5.32H2V12.56c0-.25,0-.5,0-.74,0,0,0-.05,0-.07Z"/><path d="M5.62,7.52l.24-.22a3.26,3.26,0,0,1,2.38-.81,3.24,3.24,0,0,1,1.22.28A3.14,3.14,0,0,1,10.78,7.9a4.38,4.38,0,0,1,.62,1.46,4.75,4.75,0,0,1,.12.83c0,.34,0,.68.05,1,0,.62,0,1.24,0,1.85v4.19H9V17.2c0-1.42,0-2.85,0-4.27V11.75c0-.26,0-.53,0-.79a4.12,4.12,0,0,0-.5-1.88,2.93,2.93,0,0,0-2-1.46A4.48,4.48,0,0,0,5.62,7.52Z"/><path d="M19.36,6.74H22v5c0,.33,0,.67,0,1A5.13,5.13,0,0,1,21.83,14a3.31,3.31,0,0,1-1.34,1.84,3.39,3.39,0,0,1-1.37.57,3.25,3.25,0,0,1-.7.05h0l.07-.08a3.25,3.25,0,0,0,.77-1.55,4,4,0,0,0,.11-.77c0-.24,0-.47,0-.71V6.74Z"/>
            </SvgIcon>
        );
    }

    const handleClick = (url_pagar) => {
        window.open(url_pagar);       
    }

    return (
        <BotaoItemCartao nomeItem="NuBank" subNome={ props.subNome || '' } nomeAcao={<QrCode2Icon sx={{ fontSize: 23 }}/>} onClick={ () => handleClick(props.dadoPrincipal) } icon={<NuBankIcon sx={{ fontSize: 30 }} />} />
    );
}

