import LanguageIcon from '@mui/icons-material/Language';
import BotaoItemCartao from '../BotaoItemCartao';

export default function Site(props) {
    const handleClick = (site) => {
        window.open(site);       
    }

    return (
        <BotaoItemCartao nomeItem="Site" subNome={ props.subNome || props.dadoPrincipal } onClick={ () => handleClick(props.dadoPrincipal) } icon={<LanguageIcon sx={{ fontSize: 30 }} />} />
    );
}