import YouTubeIcon from '@mui/icons-material/YouTube';
import BotaoItemCartao from '../BotaoItemCartao';

export default function CanalYoutube(props) {
    const handleClick = (canalYoutube) => {
        window.open("https://www.youtube.com/c/" + canalYoutube);
    }

    return (
        <BotaoItemCartao nomeItem="Canal YouTube" subNome={ props.subNome || '/' + props.dadoPrincipal } onClick={ () => handleClick(props.dadoPrincipal) } icon={<YouTubeIcon sx={{ fontSize: 30 }} />} />
    );
}